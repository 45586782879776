import { useSnackbar } from 'notistack'
import { useState } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, MenuItem } from '@mui/material'
// hooks
import useAuth from 'hooks/useAuth'
import useIsMountedRef from 'hooks/useIsMountedRef'
// components
import MyAvatar from 'components/MyAvatar'
import MenuPopover from 'components/MenuPopover'
import { IconButtonAnimate } from 'components/animate'
import { PATH_AUTH } from 'routes/paths'
import trackEvent from 'src/trackers'
import useLocales from 'hooks/useLocales'
import { forceDeleteCookies } from 'helpers/cookie'

export default function AccountPopover() {
  const { t } = useLocales()
  const router = useRouter()

  const { user, logout } = useAuth()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query, user }
  const isMountedRef = useIsMountedRef()

  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState<HTMLElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent.user('trigger_avatar_drop_down', trackerData)
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    trackEvent.user('trigger_logout', trackerData, {
      modal_name: 'AVATAR_DROP_DOWN',
    })
    try {
      await logout().then(() => {
        forceDeleteCookies()
        router.push(PATH_AUTH.login)
      })

      if (isMountedRef.current) {
        handleClose()
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
            noWrap
          >
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('key_logout')}
        </MenuItem>
      </MenuPopover>
    </>
  )
}
