// ----------------------------------------------------------------------

export * from './type'

export { default as NavSectionVertical } from './vertical'
export { default as NavSectionHorizontal } from './horizontal'

export function isExternalLink(path: string) {
  return path.includes('http')
}

export function getActive(
  path: string,
  pathname: string,
  asPath: string,
  isChildren,
) {
  const checkPath = path.startsWith('#')

  if (isChildren) {
    return (
      (!checkPath && pathname === path) ||
      (!checkPath && asPath.split('source')[0] === path)
    )
  }

  return (
    (!checkPath && pathname.includes(path)) ||
    (!checkPath && asPath.split('source')[0].includes(path))
  )
}
