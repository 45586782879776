import { useState } from 'react'
// @mui
import {
  Box,
  Dialog,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
// helpers
import { getLocalStorage } from 'helpers/local-storage'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

export default function ExternalRedirectPopUp({
  isOpenExternalRedirectModal,
  openExternalRedirectModal,
  onRedirect,
}) {
  const { t } = useLocales()
  const [isRedirectLoading, setIsRedirectLoading] = useState(false)
  const [hidePopupChecked, setHidePopupChecked] = useState(
    getLocalStorage('externalRedirectPopup') === 'hide',
  )

  const handleCloseModal = () => {
    openExternalRedirectModal(false)
  }

  const handleOpenExternalLink = async () => {
    setIsRedirectLoading(true)
    localStorage.setItem(
      'externalRedirectPopup',
      hidePopupChecked ? 'hide' : 'show',
    )
    await onRedirect()
    handleCloseModal()
    setIsRedirectLoading(false)
  }

  return (
    <Dialog
      open={isOpenExternalRedirectModal}
      onClose={handleCloseModal}
    >
      <Box
        style={{
          padding: '20px',
          minWidth: '500px',
        }}
      >
        <Stack direction="column" justifyContent="space-between">
          <Typography sx={(theme) => ({ ...theme.typography.h5 })}>
            {t('key_open_checkin_organizer')}
          </Typography>
          <Typography
            variant="body2"
            my="16px"
            sx={(theme) => ({ color: theme.palette.grey[500] })}
          >
            {t('key_open_checkin_desc')}
          </Typography>
        </Stack>
        <Stack direction="row" my="16px" spacing={2}>
          <LoadingButton
            fullWidth
            loading={isRedirectLoading}
            variant="contained"
            onClick={handleOpenExternalLink}
          >
            {t('key_open')}
          </LoadingButton>
          <LoadingButton
            fullWidth
            onClick={handleCloseModal}
            variant="outlined"
          >
            {t('key_cancel')}
          </LoadingButton>
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={hidePopupChecked}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  setHidePopupChecked(event.target.checked)
                }}
              />
            }
            label={
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={0.5}
              >
                <Typography variant="subtitle2">
                  {t('key_dont_show_message')}
                </Typography>
              </Stack>
            }
          />
        </Stack>
      </Box>
    </Dialog>
  )
}
