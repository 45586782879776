import { useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Stack, Drawer, Button } from '@mui/material'
// hooks
import useResponsive from 'hooks/useResponsive'
import useCollapseDrawer from 'hooks/useCollapseDrawer'
import useProductPopup from 'hooks/useProductPopup'
// utils
import cssStyles from 'utils/cssStyles'
// config
import { NAVBAR } from '~/config'
// components
import Logo from 'components/Logo'
import LogoHorizontal from 'components/LogoHorizontal'
import Scrollbar from 'components/Scrollbar'
import { NavSectionVertical } from 'components/nav-section'
import Iconify from 'components/Iconify'
//
import navConfig from './NavConfig'
import NavbarAccount from './NavbarAccount'
import CollapseButton from './CollapseButton'
import useAuth from 'hooks/useAuth'
import { menuRoleMapping } from 'helpers/user-access'
import { MAIN_APP_URL } from 'constants/index'
import useLocales from 'hooks/useLocales'
import trackEvent from '~/trackers'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
}

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const { t } = useLocales()
  const theme = useTheme()

  const { roles, user } = useAuth()
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query, user }

  const isDesktop = useResponsive('up', 'lg')

  const { openProductModal } = useProductPopup()

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer()

  const handleCreateProduct = () => {
    openProductModal(true)
    trackEvent.content('click_create_product', trackerData)
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const menuItem = menuRoleMapping(roles, navConfig)

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {isCollapse ? <Logo /> : <LogoHorizontal />}

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      {roles.includes('create-content') && (
        <Stack>
          <Button
            variant="contained"
            sx={
              isCollapse
                ? {
                    margin: '0px 10px',
                    width: '56px',
                    minWidth: 'unset',
                    padding: '11px 2px 13px 2px',
                    alignSelf: 'center',
                  }
                : {
                    margin: '0px 20px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }
            }
            onClick={handleCreateProduct}
          >
            {isCollapse ? (
              <Iconify
                icon="material-symbols:add-box-outline-rounded"
                width={22}
                height={22}
              />
            ) : (
              t('key_create_product')
            )}
          </Button>
        </Stack>
      )}

      <NavSectionVertical
        sx={{ marginBottom: '70px' }}
        navConfig={menuItem}
        isCollapse={isCollapse}
      />
      <div
        style={
          isCollapse
            ? {
                margin: '0px 10px',
                width: '56px',
                minWidth: 'unset',
                padding: '11px 2px 13px 2px',
                alignSelf: 'center',
              }
            : {
                height: '60px',
                width: NAVBAR.DASHBOARD_WIDTH,
                position: 'fixed',
                bottom: '0px',
                background: theme.palette.grey[900],
                zIndex: 1,
              }
        }
      >
        <Button
          variant="text"
          sx={
            isCollapse
              ? {
                  height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#8D8A9C14',
                  position: 'fixed',
                  bottom: '20px',
                  minWidth: 'unset',
                  paddingLeft: '18px',
                }
              : {
                  margin: '0px 20px',
                  width: NAVBAR.BASE_WIDTH - 20,
                  height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#8D8A9C14',
                  position: 'absolute',
                  bottom: '20px',
                }
          }
          href={MAIN_APP_URL}
        >
          <img src="/logo/tiptip-small.svg" alt="next" />
          <span
            style={{
              fontFamily: 'Public Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              color: '#8D8A9C',
              alignSelf: 'center',
              textTransform: 'none',
              display: isCollapse && 'none',
            }}
          >
            {t('key_open_tiptip')}
          </span>
        </Button>
      </div>
    </Scrollbar>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              backgroundColor: 'grey.900',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
