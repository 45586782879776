// @mui
import { styled } from '@mui/material/styles'
import { Box, Link, Typography } from '@mui/material'
// hooks
import useAuth from 'hooks/useAuth'
// components
import MyAvatar from 'components/MyAvatar'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: 'rgba(141, 138, 156, 0.08)',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}))

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined
}

export default function NavbarAccount({ isCollapse }: Props) {
  const { t } = useLocales()

  const { user } = useAuth()

  const getMappingRole = (role) => {
    switch (role.toLowerCase()) {
      case 'supporter':
        return t('key_supporter')
      case 'promoter':
        return 'promoter'
      default:
        return t('key_creator')
    }
  }

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography
            variant="subtitle2"
            noWrap
            sx={{ color: 'grey.0' }}
          >
            {user?.displayName}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            sx={{
              color: 'text.secondary',
              textTransform: 'capitalize',
            }}
          >
            {getMappingRole(user?.roleName)}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  )
}
