import { useState } from 'react'
import { useRouter } from 'next/router'
// @mui
import { List, Collapse, Link } from '@mui/material'
//
import { NavListProps } from '../type'
import NavItem from './NavItem'
import { getActive, isExternalLink } from '..'
import trackEvent from 'src/trackers'
import useAuth from 'hooks/useAuth'
import { useBoundPromoterStore } from 'domains/Promoter/store'

import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import ExternalRedirectPopup from 'commons/containers/ExternalRedirectPopup'

import { getLocalStorage } from 'helpers/local-storage'
import { sendLog } from 'helpers/log'
// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps
  depth: number
  hasChildren: boolean
  isCollapse?: boolean
  htmlId?: string
  isChildren?: boolean
}

const getOneTimeToken = async () => {
  try {
    const oneTimeToken = await apiClient.get(
      '/authentication/hub/v1/token',
      {
        headers: {
          Authorization: getIdToken(),
        },
      },
    )
    return oneTimeToken
  } catch (error) {
    sendLog(error)
  }
}

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
  htmlId = '',
  isChildren = false,
}: NavListRootProps) {
  const { pathname, asPath, push, query } = useRouter()
  const { user, userKYCTier3 } = useAuth()
  const active = getActive(data.path, pathname, asPath, isChildren)
  const trackerData = { pathname, query, user }
  const [open, setOpen] = useState(active)
  const { promoterStatus } = useBoundPromoterStore((state) => ({
    promoterStatus: state.promoterStatus,
  }))
  const [isOpenExternalRedirectModal, openExternalRedirectModal] =
    useState(false)

  const sourceActionHandler = (title: string) => {
    switch (title?.toLowerCase()) {
      case 'my promoter':
        trackEvent.myPromoter('click_my_promoter', trackerData, {
          current_promoter_status:
            // @ts-ignore: Unreachable code error
            promoterStatus?.data?.is_join_promoter_program
              ? 'on'
              : 'off',
        })
        return 'click_my_promoter'

      case 'tentang promoter':
        trackEvent.promoter('click_about_promoter', trackerData)
        return 'click_about_promoter'

      case 'promosikan produk':
        trackEvent.promoter(
          'click_start_promote_content',
          trackerData,
        )
        return 'click_start_promote_content'

      case 'performa penjualan':
        trackEvent.promoter('click_promoter_performance', trackerData)
        return 'click_promoter_performance'

      case 'my balance':
        trackEvent.myBalance('click_my_balance', trackerData)
        return 'click_my_balance'

      case 'dashboard':
        trackEvent.content('click_creator_dashboard', trackerData)
        return 'click_creator_dashboard'

      case 'my products':
        trackEvent.content('click_my_product', trackerData)
        return 'click_my_product'
      case 'verifikasi data':
        trackEvent.user('click_verify_kyc', trackerData)
        return 'click_verify_kyc'

      default:
        return 'unknown'
    }
  }
  const handleClickItem = async () => {
    if (data.title.toLowerCase() === 'transfer koin')
      await userKYCTier3()

    if (!hasChildren) {
      push(
        `${data.path}?source=${
          pathname ? pathname : 'unknown'
        }&source_action=${sourceActionHandler(data.title)}`,
      )
    }
    setOpen(!open)
  }

  const handleClickExternalLink = async () => {
    if (data.title === 'Event Check-in') {
      if (getLocalStorage('externalRedirectPopup') === 'hide') {
        const response = await getOneTimeToken()
        window.open(
          `${data.path}?token=${response.data.data.token_id}`,
          '_blank',
        )
      } else openExternalRedirectModal(true)
    } else {
      window.open(`${data.path}`, '_blank')
    }
  }

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link
          onClick={handleClickExternalLink}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <NavItem
            item={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
          />
        </Link>
      ) : (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
          id={htmlId}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
      <ExternalRedirectPopup
        isOpenExternalRedirectModal={isOpenExternalRedirectModal}
        openExternalRedirectModal={openExternalRedirectModal}
        onRedirect={async () => {
          const response = await getOneTimeToken()
          window.open(
            `${data.path}?token=${response.data.data.token_id}`,
            '_blank',
          )
        }}
      />
    </>
  )
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[]
  depth: number
}

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
          htmlId={`b-common-submenu-${list.title
            .toLowerCase()
            .replace(' ', '-')}`}
          isChildren
        />
      ))}
    </>
  )
}
