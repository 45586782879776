import { forwardRef } from 'react'
import NextLink from 'next/link'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

const LogoHorizontal = forwardRef<any, Props>(
  ({ disabledLink = false, sx }, ref) => {
    const logo = (
      <Box
        ref={ref}
        sx={{ width: 124, height: 56, cursor: 'pointer', ...sx }}
      >
        <svg
          width="124"
          height="56"
          viewBox="0 0 124 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_619_9918"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="124"
            height="56"
          >
            <rect width="124" height="56" fill="white" />
          </mask>
          <g mask="url(#mask0_619_9918)">
            <path
              d="M37.6817 32.5774L20.299 42.8319C19.7489 43.1565 19.1645 43.3684 18.5719 43.4747L18.6086 31.5346L19.2899 31.1326L13.1162 20.4308C11.6549 17.8978 12.5006 14.6467 15.005 13.1693C17.5094 11.6919 20.7242 12.5476 22.1855 15.0807L28.359 25.7825L32.39 23.4045C34.8944 21.9271 38.1092 22.7829 39.5705 25.3159C41.0317 27.8489 40.1861 31.1 37.6817 32.5774Z"
              fill="#CB1C4F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.2905 13.1654L37.6882 23.3945C40.1948 24.8682 41.0452 28.1181 39.5877 30.6532C38.1301 33.1884 34.9165 34.0489 32.41 32.5751L28.3755 30.203L22.2177 40.9139C20.7602 43.4491 17.5466 44.3095 15.04 42.8358C12.5335 41.362 11.6831 38.1122 13.1406 35.577L19.2986 24.8662L15.0122 22.3461C12.5056 20.8723 11.6552 17.6225 13.1128 15.0873C14.5703 12.5522 17.7839 11.6917 20.2905 13.1654Z"
              fill="#F87500"
            />
            <path
              d="M60.5574 19.7468C60.6523 19.183 60.226 18.668 59.6644 18.668L47.8421 18.668C47.4035 18.668 47.0278 18.988 46.9503 19.4278L46.5145 21.9011C46.4148 22.467 46.8418 22.987 47.4063 22.987H53.0369L50.7626 36.242C50.6657 36.8066 51.0923 37.3237 51.6551 37.3237H54.7353C55.1755 37.3237 55.552 37.0014 55.6278 36.5597L57.9566 22.987H59.248C59.6893 22.987 60.0664 22.6631 60.141 22.2201L60.5574 19.7468Z"
              fill="#CB1C4F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.8736 25.4372C79.1668 24.4405 77.8893 23.9422 76.041 23.9422C74.5914 23.9422 73.4407 24.3113 72.589 25.0496L72.7249 24.3021H68.5391L67.1877 32.5705C66.7973 33.6542 65.3791 34.0752 64.9738 34.0752C64.4302 34.0752 64.1584 33.826 64.1584 33.3277C64.1584 33.2354 64.1675 33.1339 64.1856 33.0231L65.5185 25.3865C65.6172 24.821 65.1903 24.3021 64.6264 24.3021H61.8463C61.407 24.3021 61.031 24.623 60.9542 25.0636L59.4561 33.6599C59.3836 34.1029 59.3474 34.6197 59.3474 35.2103C59.3474 35.7825 59.5105 36.327 59.8366 36.8438C60.3259 37.619 61.395 38.0066 63.044 38.0066C64.1312 38.0066 65.6873 37.2337 66.539 36.5139L66.5442 36.5084L65.5615 42.4824C65.4696 43.0449 65.8955 43.5568 66.4553 43.5568H69.2237C69.6734 43.5568 70.055 43.2209 70.1202 42.7677L70.9854 36.7608C71.5833 37.5914 72.6434 38.0066 74.1655 38.0066C75.6877 38.0066 76.9561 37.5544 77.9709 36.65C78.9856 35.7272 79.647 34.389 79.9551 32.6356L80.4987 29.3963C80.5893 28.9164 80.6346 28.4642 80.6346 28.0397C80.6346 26.9876 80.3809 26.1201 79.8736 25.4372ZM74.6276 33.5215C74.1927 33.8537 73.7216 34.0199 73.2142 34.0199C72.3988 34.0199 71.8642 33.7615 71.6105 33.2446L72.426 28.5934C72.7159 28.132 73.332 27.9012 74.2743 27.9012C74.8541 27.9012 75.2618 28.0581 75.4974 28.3719C75.733 28.6857 75.8508 28.981 75.8508 29.2579C75.8508 29.5347 75.8326 29.7654 75.7964 29.95L75.443 32.0265C75.3343 32.6909 75.0625 33.1893 74.6276 33.5215Z"
              fill="#CB1C4F"
            />
            <path
              d="M61.4573 21.8847C61.3463 22.4553 61.775 22.987 62.3461 22.987H65.3626C65.7857 22.987 66.1525 22.6887 66.2462 22.2684L66.7974 19.7952C66.9261 19.2177 66.4952 18.668 65.9139 18.668L62.8273 18.668C62.3948 18.668 62.0226 18.9793 61.9386 19.4114L61.4573 21.8847Z"
              fill="#CB1C4F"
            />
            <path
              d="M91.5227 19.7468C91.6176 19.183 91.1913 18.668 90.6297 18.668L78.8074 18.668C78.3688 18.668 77.9932 18.988 77.9157 19.4278L77.4798 21.9011C77.3801 22.467 77.8072 22.987 78.3716 22.987H84.0022L81.7279 36.242C81.631 36.8066 82.0577 37.3237 82.6204 37.3237H85.7006C86.1409 37.3237 86.5174 37.0014 86.5932 36.5597L88.922 22.987H90.2133C90.6546 22.987 91.0318 22.6631 91.1063 22.2201L91.5227 19.7468Z"
              fill="#F87500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M110.839 25.4372C110.132 24.4405 108.855 23.9422 107.006 23.9422C105.557 23.9422 104.406 24.3113 103.554 25.0496L103.69 24.3021H99.5044L98.1531 32.5705C97.7626 33.6542 96.3444 34.0752 95.9392 34.0752C95.3956 34.0752 95.1237 33.826 95.1237 33.3277C95.1237 33.2354 95.1328 33.1339 95.1509 33.0231L96.4838 25.3865C96.5825 24.821 96.1556 24.3021 95.5918 24.3021H92.8117C92.3724 24.3021 91.9964 24.623 91.9196 25.0636L90.4214 33.6599C90.349 34.1029 90.3127 34.6197 90.3127 35.2103C90.3127 35.7825 90.4758 36.327 90.802 36.8438C91.2912 37.619 92.3603 38.0066 94.0093 38.0066C95.0966 38.0066 96.6527 37.2337 97.5043 36.5139L97.5095 36.5084L96.5269 42.4824C96.4349 43.0449 96.8608 43.5568 97.4206 43.5568H100.189C100.639 43.5568 101.02 43.2209 101.086 42.7677L101.951 36.7608C102.549 37.5914 103.609 38.0066 105.131 38.0066C106.653 38.0066 107.921 37.5544 108.936 36.65C109.951 35.7272 110.612 34.389 110.92 32.6356L111.464 29.3963C111.555 28.9164 111.6 28.4642 111.6 28.0397C111.6 26.9876 111.346 26.1201 110.839 25.4372ZM105.593 33.5215C105.158 33.8537 104.687 34.0199 104.18 34.0199C103.364 34.0199 102.83 33.7615 102.576 33.2446L103.391 28.5934C103.681 28.132 104.297 27.9012 105.24 27.9012C105.819 27.9012 106.227 28.0581 106.463 28.3719C106.698 28.6857 106.816 28.981 106.816 29.2579C106.816 29.5347 106.798 29.7654 106.762 29.95L106.408 32.0265C106.3 32.6909 106.028 33.1893 105.593 33.5215Z"
              fill="#F87500"
            />
            <path
              d="M92.4227 21.8847C92.3117 22.4553 92.7403 22.987 93.3114 22.987H96.3279C96.751 22.987 97.1178 22.6887 97.2115 22.2684L97.7628 19.7952C97.8915 19.2177 97.4605 18.668 96.8792 18.668L93.7926 18.668C93.3602 18.668 92.988 18.9793 92.9039 19.4114L92.4227 21.8847Z"
              fill="#F87500"
            />
          </g>
        </svg>
      </Box>
    )

    if (disabledLink) {
      return <>{logo}</>
    }

    return <NextLink href="/">{logo}</NextLink>
  },
)

LogoHorizontal.displayName = 'LogoHorizontal'

export default LogoHorizontal
