import { m } from 'framer-motion'
// next
import NextLink from 'next/link'
// @mui
import { styled } from '@mui/material/styles'
import { Button, Typography, Container } from '@mui/material'
// layouts
import Layout from '../layouts'
// components
import Page from '../components/Page'
import { MotionContainer, varBounce } from '../components/animate'
import useAuth from 'hooks/useAuth'
import { UserTypes } from '~/@types/profile'
import { PATH_DASHBOARD } from 'routes/paths'
import config from 'configs/app'
import StateImage from 'components/state-image'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

Page403.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="logoOnly">{page}</Layout>
}

// ----------------------------------------------------------------------

export default function Page403() {
  const { user } = useAuth()
  const dashboardUrl =
    user?.role === UserTypes.Creator
      ? PATH_DASHBOARD.creator_center.dashboard
      : PATH_DASHBOARD.general.promoter_program.about

  return (
    <Page title="403 Forbidden">
      <Container component={MotionContainer}>
        <ContentStyle
          sx={{ textAlign: 'center', alignItems: 'center' }}
        >
          <m.div variants={varBounce().in}>
            <StateImage
              illustrationName="illustration_checkout"
              type="illustration"
              sx={{ height: 160, my: { xs: 5 } }}
            />
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography
              variant="h5"
              paragraph
              sx={{ marginBottom: 1 }}
            >
              Akun kamu tidak memiliki akses ke halaman ini
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography
              sx={{
                color: 'text.secondary',
                marginBottom: 6,
                fontSize: 14,
              }}
            >
              Silakan hubungi tim kami untuk mempelajari lebih lanjut
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <NextLink href={dashboardUrl} passHref>
              <Button
                size="medium"
                variant="outlined"
                sx={{
                  width: { xs: 113, sm: 221 },
                  marginRight: 1,
                }}
              >
                Ke Home
              </Button>
            </NextLink>

            <NextLink
              href={config.supportCenterUrl + 'home'}
              passHref
            >
              <Button
                size="medium"
                variant="contained"
                sx={{ width: { xs: 113, sm: 221 } }}
              >
                Hubungi CS
              </Button>
            </NextLink>
          </m.div>
        </ContentStyle>
      </Container>
    </Page>
  )
}
