// @mui
import { List } from '@mui/material'
// hooks
import useLocales from 'hooks/useLocales'
//
import { NavSectionProps } from '../type'
import { ListSubheaderStyle, BoxContainerStyle } from './style'
import NavList from './NavList'
import RoleBasedGuard from '~/guards/RoleBasedGuard'
import { useBoundCommunityStore } from 'domains/Community/store'
import isEmpty from 'helpers/is-empty'
import useAuth from 'hooks/useAuth'

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}: NavSectionProps) {
  const { t } = useLocales()
  const { user } = useAuth()
  const { communityInfo } = useBoundCommunityStore((state) => ({
    communityInfo: state.communityInfo,
  }))

  return (
    <BoxContainerStyle {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <RoleBasedGuard roles={group.roles}>
            <ListSubheaderStyle
              disableGutters
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {t(group.subheader)}
            </ListSubheaderStyle>
          </RoleBasedGuard>

          {group.items.map((list) => {
            if (
              (isEmpty(communityInfo.community_id) &&
                t(list.title).toLowerCase() ===
                  t('key_mycommunity').toLowerCase()) ||
              (!user?.kyc_tier_three?.whitelisted &&
                t(list.title).toLowerCase() ===
                  t('key_coin_transfer').toLowerCase())
            ) {
              return null
            }

            return (
              <NavList
                key={list.title + list.path}
                data={{ roles: group.roles, ...list }}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
                htmlId={`b-common-menu-${list.title
                  .toLowerCase()
                  .replace(' ', '-')}`}
              />
            )
          })}
        </List>
      ))}
    </BoxContainerStyle>
  )
}
