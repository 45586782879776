import { ReactNode } from 'react'
// next
import { useRouter } from 'next/router'
// hooks
import useAuth from 'hooks/useAuth'
import Login from '~/pages/auth/login'
import Page403 from '~/pages/403'
// components
import LoadingScreen from 'components/LoadingScreen'
import { IsAccessible } from 'helpers/user-access'
import { PATH_AUTH } from 'routes/paths'

type Props = {
  children: ReactNode
}

export default function AuthGuard({ children }: Props) {
  const { isAuthenticated, isInitialized, roles } = useAuth()

  const { replace } = useRouter()

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    replace(PATH_AUTH.login)
    return <Login />
  }

  if (!IsAccessible(roles)) {
    return <Page403 />
  }

  return <>{children}</>
}
