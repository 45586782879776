// @mui
import { Box, Tooltip, ListItemButtonProps } from '@mui/material'
// hooks
import useLocales from 'hooks/useLocales'
// guards
import RoleBasedGuard from '~/guards/RoleBasedGuard'
//
import Iconify from '../../Iconify'
//
import { NavItemProps } from '../type'
import {
  ListItemStyle,
  ListItemTextStyle,
  ListItemIconStyle,
} from './style'

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps

export default function NavItem({
  item,
  depth,
  active,
  open,
  isCollapse,
  ...other
}: Props) {
  const { t } = useLocales()

  const {
    title,
    icon,
    info,
    children,
    disabled,
    caption,
    roles,
    html_id,
  } = item

  const renderContent = (
    <ListItemStyle
      id={html_id}
      depth={depth}
      active={active}
      disabled={disabled}
      {...other}
    >
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      {depth !== 1 && <DotIcon active={active && depth !== 1} />}
      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={t(title)}
        secondary={
          caption && (
            <Tooltip title={t(caption)} placement="top-start">
              <span>{t(caption)}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
          color: active && depth !== 1 ? 'grey.0' : 'inherit',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />
      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (
            <Iconify
              icon={
                open
                  ? 'eva:arrow-ios-downward-fill'
                  : 'eva:arrow-ios-forward-fill'
              }
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
            />
          )}
        </>
      )}
    </ListItemStyle>
  )

  return (
    <RoleBasedGuard roles={roles}>{renderContent}</RoleBasedGuard>
  )
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean
}

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  )
}
