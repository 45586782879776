import { useRouter } from 'next/router'
import { PROTECTED_PATH } from 'routes/paths'
import navConfig from '~/layouts/dashboard/navbar/NavConfig'

export const menuRoleMapping = (
  roles: string[],
  menu: typeof navConfig,
) => {
  return menu
    .map((main) => ({
      ...main,
      items: main.items
        .map((item) => ({
          ...item,
          children: item.children?.filter((child) =>
            roles?.includes(
              PROTECTED_PATH?.find((x) => x.path === child.path)
                ?.access || '',
            ),
          ),
        }))
        .filter(
          (item) =>
            (item.children !== undefined &&
              item.children.length > 0) ||
            (item.children === undefined &&
              roles?.includes(
                PROTECTED_PATH.find((x) => x.path === item.path)
                  ?.access || '',
              )),
        ),
    }))
    .filter(
      (main) => main.items !== undefined && main.items.length > 0,
    )
}

export function IsAccessible(roles: string[]) {
  const { pathname } = useRouter()
  const access =
    PROTECTED_PATH.find((x) => x.path === pathname)?.access || ''

  if (!access) return true
  return roles?.includes(access)
}
