import apiClient from 'helpers/api-client'
import { StateCreator } from 'zustand'
import { IMyPromoter } from './interface'

export const createMyPromoterSlice: StateCreator<IMyPromoter> = (
  set,
) => ({
  // states
  promotedItemList: [],
  promoterStatusUpdate: [],
  promoterStatus: [],
  promoterListIsLoading: false,
  promoterStatusIsError: false,
  promoterListMetadata: {
    limit: 10,
    load_more: false,
    page: 1,
    total: 0,
  },

  // actions
  getPromotedItemList: async (page: number, rowsPerPage: number) => {
    set({ promoterListIsLoading: true, promotedItemList: [] })
    const res = await apiClient.get(
      '/promoter/hub/v1/conversion/creator/performance/list',
      {
        params: {
          page: page,
          limit: rowsPerPage,
        },
      },
    )
    try {
      set({
        promoterListMetadata: res.data._metadata,
        promoterListIsLoading: false,
        promotedItemList: res.data.data,
      })
    } catch (error) {
      set({ promoterListIsLoading: false, promotedItemList: [] })
    }
  },
  reseterStore: () => {
    set({
      promoterStatusUpdate: [],
    })
  },
  promoterStatusHandler: async (action: string) => {
    await apiClient
      .put(`/user/hub/v1/creator/promoter-program`, action)
      .then((response) => {
        set({
          promoterStatusUpdate: response.data,
          promoterStatusIsError: false,
        })
      })
      .catch((error) => {
        set({
          promoterStatusUpdate: error,
          promoterStatusIsError: true,
        })
        console.error(error)
      })
  },
  getPromoterStatus: async () => {
    const res = await apiClient.get(
      '/user/hub/v1/creator/promoter-program',
    )
    try {
      set({ promoterStatus: res.data })
    } catch (error) {
      set({ promoterStatus: error })
    }
  },
})
