import { useState } from 'react'
import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from '@mui/material'
import { Campaign, AddBox } from '@mui/icons-material'
import SvgIconStyle from 'components/SvgIconStyle'
import { useRouter } from 'next/router'
import useProductPopup from 'hooks/useProductPopup'
import { PATH_DASHBOARD } from 'routes/paths'
import useAuth from 'hooks/useAuth'
import { UserTypes } from '~/@types/profile'
import useLocales from 'hooks/useLocales'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
)

const ICONS = {
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  animate: getIcon('ic_animate'),
}

// ----------------------------------------------------------------------

export default function NavbarBottom() {
  const theme = useTheme()
  const { t } = useLocales()
  const { pathname, push } = useRouter()
  const [value, setValue] = useState(false)
  const { user } = useAuth()
  const isCreator = user.role === UserTypes.Creator

  const { openProductModal } = useProductPopup()

  const bottomNavHandler = (value: any) => {
    let path = ``
    if (value === 'dasbor') {
      path = isCreator
        ? PATH_DASHBOARD.creator_center.dashboard
        : PATH_DASHBOARD.general.promoter_program.about
    } else if (value === 'produk') {
      path = isCreator
        ? PATH_DASHBOARD.creator_center.my_content
        : PATH_DASHBOARD.general.promoter_program.promote
    } else if (value === 'promoter') {
      path = PATH_DASHBOARD.creator_center.my_promoter
    } else if (value === 'performa') {
      path = PATH_DASHBOARD.general.promoter_program.sales_performance
    } else if (value === 'saldo') {
      path = PATH_DASHBOARD.general.balance.root
    }
    return `${path}?source=${
      pathname ? pathname : 'unknown'
    }&source_action=click-bottom-nav-${value}`
  }

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue)

          const isCreateContent = newValue === 'create-konten'
          if (isCreateContent) {
            openProductModal(true)
          } else {
            push(bottomNavHandler(newValue))
          }
        }}
      >
        <BottomNavigationAction
          label="Dasbor"
          icon={ICONS.dashboard}
          value="dasbor"
        />
        <BottomNavigationAction
          label={isCreator ? 'Produk' : 'Cari Konten'}
          icon={ICONS.components}
          value="produk"
        />
        {isCreator && (
          <BottomNavigationAction
            label={t('key_create')}
            icon={
              <div
                style={{
                  backgroundColor: theme.palette.background.paper,
                  height: 48,
                  width: 48,
                  borderRadius: 50,
                  marginTop: -26,
                }}
              >
                <AddBox
                  sx={{
                    color: theme.palette.primary.main,
                    height: 42,
                    width: 42,
                    marginTop: 0.6,
                  }}
                />
              </div>
            }
            value="create-konten"
          />
        )}
        {isCreator ? (
          <BottomNavigationAction
            label="Promoter"
            icon={<Campaign />}
            value="promoter"
          />
        ) : (
          <BottomNavigationAction
            label="Performa"
            icon={ICONS.analytics}
            value="performa"
          />
        )}
        <BottomNavigationAction
          label="Saldo"
          icon={ICONS.animate}
          value="saldo"
        />
      </BottomNavigation>
    </Paper>
  )
}
