// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
import SvgIconStyle from '../../../components/SvgIconStyle'
import { ORGANIZER_APP_URL } from 'constants/index'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  diamond: getIcon('ic_diamond'),
  menuItem: getIcon('ic_menu_item'),
  upload: getIcon('ic_upload'),
  components: getIcon('ic_components'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  animate: getIcon('ic_animate'),
  authenticator: getIcon('ic_authenticator'),
  ticket: getIcon('ic_ticket'),
  groups: getIcon('ic_groups'),
  verified: getIcon('ic_verified'),
  sendToMobile: getIcon('ic_send_to_mobile'),
}

const navConfig = [
  // CREATOR CENTER
  // ----------------------------------------------------------------------
  {
    subheader: 'key_creator_center',
    items: [
      {
        title: 'key_dashboard',
        path: PATH_DASHBOARD.creator_center.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: 'key_myproduct',
        path: PATH_DASHBOARD.creator_center.my_content,
        icon: ICONS.components,
      },
      {
        title: 'Event Check-in',
        path: `${ORGANIZER_APP_URL}/organization`,
        html_id: 'b-navigation-ticketing-checkin',
        icon: ICONS.ticket,
      },
      {
        title: 'key_sales',
        path: PATH_DASHBOARD.creator_center.revenue,
        icon: ICONS.analytics,
      },
      {
        title: 'key_subscription',
        path: PATH_DASHBOARD.creator_center.subscription,
        icon: ICONS.diamond,
      },
      {
        title: 'key_mypromoter',
        path: PATH_DASHBOARD.creator_center.my_promoter,
        icon: ICONS.elements,
      },
      {
        title: 'key_coin_transfer',
        path: PATH_DASHBOARD.creator_center.transfer_coin.root,
        icon: ICONS.sendToMobile,
        html_id: 'b-navigation-transfer-coin',
      },
    ],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'key_general',
    items: [
      {
        title: 'key_mycommunity',
        path: PATH_DASHBOARD.general.my_community.root,
        icon: ICONS.groups,
        children: [
          {
            title: 'key_setting',
            path: PATH_DASHBOARD.general.my_community.setting,
          },
        ],
      },
      {
        title: 'key_promoter_program',
        path: PATH_DASHBOARD.general.promoter_program.root,
        icon: ICONS.carousel,
        children: [
          {
            title: 'key_about_promoter',
            path: PATH_DASHBOARD.general.promoter_program.about,
          },
          {
            title: 'key_promote_product',
            path: PATH_DASHBOARD.general.promoter_program.promote,
          },
          {
            title: 'key_selling_performance',
            path: PATH_DASHBOARD.general.promoter_program
              .sales_performance,
          },
        ],
      },
      {
        title: 'key_mybalance',
        path: PATH_DASHBOARD.general.balance.root,
        icon: ICONS.animate,
      },
      {
        title: 'key_data_verification',
        path: PATH_DASHBOARD.general.verification.root,
        icon: ICONS.verified,
      },
    ],
  },
]

export default navConfig
